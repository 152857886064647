import React, { useState, useEffect } from "react";
import * as AzureService from "../api/ticketsApiAzure";
import * as tConst from "../common/Constants";
import store from 'store2';
import TriageToolbar from "./TriageToolbar";
import { Tabs } from 'antd';
import TriageForm from './TriageForm';



const TriagePage = ({auth, updateAuth}) => {
    const [projects, setProjects] = useState([]);
    const [selectedProject,setSelectedProject] = useState(store.get("SelectedProject")); // "Test for oyvindh"
    const [items, setItems] = useState([]);
    const [itemIds, setItemsIds] = useState([]);
    const [openOnClickStatus, setOpenOnClickStatus] = useState(store.get("openOnClickStatus"));
    const [isLoaded, setIsLoaded] = useState(false);
    const [itemQuery, setItemQuery] = useState({query: "Select [System.Id] , [System.Title], [System.State], [Custom.EndIteration], [Custom.StartIteration] From WorkItems Where [Custom.EndIteration] <> '' AND [State] <> 'Closed' AND [State] <> 'Removed' and [System.TeamProject] = @project order by [Custom.PlanningPriority] asc, [Custom.StartIteration] asc, [System.CreatedDate] asc"});
    const [refresh, setRefresh] = useState();
    const [showChartMenu, setShowChartMenu] = useState(false);
    const [viewId, setViewId] = useState(store.get("SelectedViewId"));
    const [assigneeList, setAssigneeList] = useState([]);
    const [assigneeId, setAssigneeId] = useState(tConst.ADO_ASSIGNED_ALL);

    const [hideProjects, setHideProjects] = useState((store.get("hideProjects") ? JSON.parse(store.get("hideProjects")):[]));

    const maxTitleLength = 40;

       //#region Project
       useEffect(() => {
        setIsLoaded(false);
        if (auth && auth.un!== "" && auth.pw !== ""){

            AzureService.getProjects(auth).then(async (response) => 
            {
                setProjects(response.value.map(project => {return {label: project.name, id: ""+project.id, name: project.name, value: project.id}}));
                setIsLoaded(true);
            }).catch((error) => {
                updateAuth({un:"", pw: ""})
            });   
        }
    }, [auth, updateAuth]);
    //#endregion Project

        //#region  WorkItems
        useEffect(() => {
            setIsLoaded(false);
    
            const _selectedProject = projects ? projects.filter((filt) => {
                return filt.value === selectedProject}): null;
                
            const _selectedProjectName = _selectedProject && _selectedProject[0] ? _selectedProject[0].name : "";
            console.log("_selectedProjectName: "+_selectedProjectName);
    
            setItems([]);
    
            if (auth && auth.un!== "" && auth.pw !== ""){
    
                AzureService.getItemsList(auth, {query: tConst.ADO_TRIAGE_QUERY}, _selectedProjectName).then(
                    async (response) => 
                        {
                            let ids = response.workItems.map((item) => item.id)
                            setItemsIds(ids);
                        });
            }
        }, [auth, itemQuery, selectedProject, refresh, projects]);
    
    
        useEffect(() => {
    
    
            if (auth && auth.un!== "" && auth.pw !== "" && itemIds.length >0) {
    
    
    
                
                let first = 0;
                let batchSize = 200;
                let pageIds = null;
                while (first< itemIds.length) {           
    
                    pageIds = itemIds.slice(first,first+batchSize);
                    AzureService.getItems(auth, pageIds).then(
                        async (response) => 
                        {
                            const tempItems = response.value.map(item => {
                                const pp = item.fields[tConst.AF_PLANNING_PRIORITY]
                                item.planningPri = ((pp) ? 1*pp : 99999 )
                                const _assignee = item.fields[tConst.AF_ASSIGNED_TO] ? item.fields[tConst.AF_ASSIGNED_TO].displayName : tConst.ADO_UNASSIGNED;
                                item.Assignee = _assignee;
                                const _reporter = item.fields[tConst.AF_REPORTER] ? item.fields[tConst.AF_REPORTER].displayName : "";
                                item.Reporter = _reporter;

                                try {
                                    item.TriageData = item.fields[tConst.AF_TRIAGE_DATA] ? JSON.parse(item.fields[tConst.AF_TRIAGE_DATA].replace(/&quot;/g, '"').replace(/<\/?[^>]+>/gi, '')) : [];                                    
                                } catch (error) {
                                    console.warn("Failed Partsing TriageData: " +error);
                                    console.log("Failed Partsing TriageData: ", item.fields[tConst.AF_TRIAGE_DATA].replace(/&quot;/g, '"').replace(/<\/?[^>]+>/gi, ''));
                                    item.TriageData = [];
                                }

    
                                return item;
                            })
                    
                            const _items = items;
                            _items.push(...tempItems)
                            setItems(_items);
    
                            //Todo: Remove this log line later
                            console.log("items.length: "+items.length + " itemIds.length: " + itemIds.length)
                            
                            if (items.length === itemIds.length) {
                                let i = 0;
                                const _assigneeList = items.map((item) => item.Assignee).filter((value, index, self) => self.indexOf(value) === index).sort((a,b) => a.localeCompare(b)).map((item) => {return {label: item, value: item}});
                                setAssigneeList(_assigneeList);
                                setIsLoaded(true);
                            }
                        }
                    );
                    first = first + batchSize;
                }
    
                
    
            }
            else {
                setIsLoaded(true);
                setItems([]);
            }
        // Will not trigger this on items - it is just used to write it
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [auth, itemIds]);
    


    console.log("TriagePage: selectedProject: "+selectedProject);   
    const filteredProjects = projects
        .filter(project => tConst.ADO_TRIAGE_PROJECTS.includes(project.id))
        .sort((a, b) => a.name.localeCompare(b.name));

    return (
        <div>
            {isLoaded && filteredProjects.length > 0 && (
                <>
                    <TriageToolbar 
                        projects={filteredProjects} 
                        selectedProject={selectedProject}
                        projectOnChange={(value)=> {
                            store.set("SelectedProject", value);
                            setSelectedProject(value);
                        }} 
                     />
                </>
            )}

            {isLoaded && items.length > 0 && (
                <>
                    <Tabs        
                        defaultActiveKey="1"
                        tabPosition="left"
                        items={items.map(item => {
                            return {
                                label: "[" + item.id + "] " + (item.fields[tConst.AF_TITLE].length > maxTitleLength ? item.fields[tConst.AF_TITLE].substring(0, maxTitleLength) + "..." : item.fields[tConst.AF_TITLE]),
                                key: item.id,
                                children: <TriageForm item={item} auth={auth} refrech={() => setRefresh(Date.now())}/>
                            }
                        }
                        )}
                            
                        
                        />

                </>
            )}
        </div>
    );
};

export default TriagePage;