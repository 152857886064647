import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap"
import * as AzureService from "../../api/ticketsApiAzure";
import * as tConst from "../../common/Constants";
import AddNew from "./AddNew";


const ChildrenMenuToolbar = ({selectedTicket, onClose, auth, onSetRefresh }) => {
    const [childrenIds, setChildrenIds] = useState([]);
    const [children, setChildren] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [showAddNew, setShowAddNew] = useState(false);  
    useEffect(() => {
        if (auth && auth.un!== "" && auth.pw !== ""){
                setIsLoaded(false);
    
            AzureService.getChildrenList(auth, selectedTicket.id).then(async (response) => 
            {
                setChildrenIds(response);
            });   
        }
    }, [auth, selectedTicket]);

    useEffect(() => {
        if (childrenIds?.length > 0) {

            if (auth && auth.un!== "" && auth.pw !== ""){
                
                AzureService.getItems(auth, childrenIds).then(async (response) => 
                {
                    console.log(JSON.stringify(response, null, 4));
                    setChildren(response.value);
                    setIsLoaded(true);
                });   
            }
        }
        else {
            setChildren([])
            setIsLoaded(true);
        }
        }, [auth, childrenIds]);

    if (!isLoaded) {
        return (<></>)
    }
    else{

        return (
            <>
                <div>
                    {children.length >0 && <h6>Children:</h6>}

                    {children.length >0 && <Button variant="info" size="sm" onClick={()=>{
                        PlanningToChildren(auth, selectedTicket, children);
                        onClose();
                        
                    }}>Inherit</Button>}
                    &nbsp;
                    {children.length >0 && <Button variant="info" size="sm" onClick={()=>{
                        PlanningToChildren(auth, selectedTicket, children);
                        addChildrenToTimeline(auth, selectedTicket, children);
                        onClose();
                    }}>Add</Button>}
                    &nbsp;
                    <Button variant="info" size="sm" onClick={()=>{
                        const _toggle = !showAddNew;
                        setShowAddNew(_toggle);
                    }}>New</Button>
                </div>
                <AddNew
                    showAddNew={showAddNew}
                    parent = {selectedTicket}
                    auth = {auth}
                    onClose = {onClose}
                    onSetRefresh = {onSetRefresh}
                    handleClose={()=>{
                        setShowAddNew(false);
                    }}
                />
                
                <div>
                    <table className="table table-sm table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Title</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                    <tbody>

                    {children.map((item) =>(
                        <tr key={item.id}>
                            <td>
                                <a href={tConst.AZURE_DEVOPS_BASE_TICKET_URI+item.id} target="_blank" rel="noopener noreferrer">
                            {item.id}</a>
                            </td>
                            <td>
                                <a href={tConst.AZURE_DEVOPS_BASE_TICKET_URI+item.id} target="_blank" rel="noopener noreferrer">
                                {item.fields[tConst.AF_TITLE] }</a>
                            </td>
                            <td>
                                {item.fields[tConst.AF_STATE]}
                            </td>
                        </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
    
} 

const PlanningToChildren = (auth, selectedTicket, children) =>{
    const initiative = selectedTicket.fields[tConst.AF_INITIATIV];
    const PlanningPriority = selectedTicket.fields[tConst.AF_PLANNING_PRIORITY];
    const childrenIds = children.map(item => (item[tConst.AF_ID]));
    const _PlanningPriority = 0.1 + Number(PlanningPriority)
    
    console.log("initiative" + initiative + " / PlanningPriority: "+ PlanningPriority + " / Children: "+ JSON.stringify(childrenIds))
    
    const data = JSON.stringify([
        {
            "op": "replace",
            "path": "/fields/"+tConst.AF_INITIATIV,
            "value": initiative
        },
        {
            "op": "replace",
            "path": "/fields/"+tConst.AF_PLANNING_PRIORITY,
            "value": _PlanningPriority
        },
    ])  

    childrenIds.map((item) => {
        return AzureService.updateTicket(auth, item, data).then((response) => {
            console.log("Updated Ticket: " +JSON.stringify(response));
        })
    })
}

const addChildrenToTimeline = (auth, selectedTicket, children) =>{
    const _missingChildren = children.filter( item => (!item.fields[tConst.AF_START_ITERATION] || !item.fields[tConst.AF_END_ITERATION]))
    const childrenIds = _missingChildren.map(item => (item[tConst.AF_ID]));

    const _start = new Date(selectedTicket.fields[tConst.AF_START_ITERATION]);
    const _end = new Date(selectedTicket?.fields[tConst.AF_END_ITERATION]);

    const data = JSON.stringify([
      {
        "op": "replace",
        "path": "/fields/"+tConst.AF_START_ITERATION,
        "value": _start
      },
      {
        "op": "replace",
        "path": "/fields/"+tConst.AF_END_ITERATION,
        "value": _end
      },
    ])  
  
    childrenIds.map((item) => {
        return AzureService.updateTicket(auth, item, data).then((response) => {
            console.log("Updated Ticket: " +JSON.stringify(response));
        })
    })
    

}


export default ChildrenMenuToolbar