import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as tConst from "../../common/Constants";
import ChartMenuMoveExtendButtons from "./ChartMenuMoveExtendButtons"
import ChildrenMenuToolbar from "./ChildrenMenuToolbar"
import ChartMenuToolbar from "./ChartMenuToolbar"
import ChartMenuForm from "./ChartMenuForm";

class ChartMenuAdo extends Component {
  render() {
    const selectedTicket = this.props.selectedTicket;

    if (this.props.showChartMenu && selectedTicket) {
      console.log("ChartMenuAdo..props.selectedTicket" + JSON.stringify(selectedTicket, null, 4));
      const _fromDate = new Date (selectedTicket.fields[tConst.AF_START_ITERATION]);
      const _toDate = new Date(selectedTicket.fields[tConst.AF_END_ITERATION]);
      const _assignedTo =  selectedTicket.fields[tConst.AF_ASSIGNED_TO];
      const _initiaitve = selectedTicket.fields[tConst.AF_INITIATIV];
      const _jiraLink = selectedTicket ? "https://jira.sensio.no/browse/"+selectedTicket.fields[tConst.AF_JIRA_LINK] : "";
      return (
        <>
          <Modal show={this.props.showChartMenu}>
            <Modal.Header>
              <Modal.Title>
                <a href={tConst.AZURE_DEVOPS_BASE_TICKET_URI + this.props.menuTicketID} target="_blank" rel="noopener noreferrer">
                  [{this.props.menuTicketID}] 
                </a>
                &nbsp;
                  {selectedTicket?.fields[tConst.AF_TITLE]}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <ChartMenuForm
                  selectedTicket={selectedTicket}
                  fromDate = {_fromDate}
                  toDate = {_toDate}
                  assignedTo = {_assignedTo}
                  initiaitve = {_initiaitve}
                  jiraLink = {_jiraLink}
                  onUpdateTicket={this.props.onUpdateTicket}  
                  onSetRefresh={this.props.onSetRefresh}
                />
                <ChartMenuToolbar
                  auth={this.props.auth}
                  selectedTicket={selectedTicket} 
                  onClose={()=> {
                    this.props.onSetRefresh();
                    this.props.handleClose();
                  }}
                />
                </div>

    {/*} 

                <br />
                Demo:{" "}
                <Select
                  options={tConst.DEMO_SELECT_LIST}
                  name="view"
                  defaultValue={tConst.DEMO_SELECT_LIST.find((item) => item.value === demo)}
                  onChange={(event) => this.props.updateDemo(key, event.value)}
                  style={{ width: "40px" }}
                />
    {*/} 

                <hr/>
                <ChartMenuMoveExtendButtons 
                  selectedTicket={selectedTicket} 
                  onExtendIteration = {this.props.onExtendIteration}
                  enableHalfIterations = {this.props.enableHalfIterations}
                  onMoveIteration = {this.props.onMoveIteration}
                />
                <hr/>
                <ChildrenMenuToolbar
                  auth={this.props.auth}
                  selectedTicket={selectedTicket} 
                  onPlanningToChildren={()=> {this.props.onPlanningToChildren(selectedTicket)}}
                  onSetRefresh = {this.props.onSetRefresh}
                  onClose={()=> {
                    this.props.onSetRefresh();
                    this.props.handleClose();
                  }}
                />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.props.handleClose} variant="primary">
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
    else{
      return <></>
    }  
  
  }
}

export default ChartMenuAdo;
