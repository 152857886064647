import React, { useState} from "react";
import { Button, Input } from"antd";
import * as AzureService from "../../api/ticketsApiAzure";
import *as tConst from "../../common/Constants";


// import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";



const AddNew = ({...props}) => {
    // eslint-disable-next-line no-unused-vars
    const [title, setTitle] = useState("");

    // const inputStyle = { width:'100%' }
    
    if (props.showAddNew){
    return(
        <>

            <label>New titles</label>&nbsp;
            <Input placeholder="type in titles, separate with ';'" value={title} onChange={(e) => setTitle(e.target.value)} style={{ width: 300 }} />&nbsp;
            <Button onClick={()=> {
                AddNewTickets(props.auth, title, props.parent, props.onSetRefresh);
                props.onClose();
            }}>Save</Button>
        </>
    )
    }
    else {
        return <></>
    }

    
}

export default AddNew;

function AddNewTickets(auth, titles, parent, onSetRefresh){
    let titlesArray = titles.split(';');
    titlesArray.forEach(title => {
        const newItem = {
            newTitle: title, 
            path: parent.fields[tConst.AF_AREA_PATH],
            tags : parent.fields[tConst.AF_TAGS] ? parent.fields[tConst.AF_TAGS] : "",
            parentId: parent.id,
            initiative: parent.fields[tConst.AF_INITIATIV] ? parent.fields[tConst.AF_INITIATIV] : "",
            startIteration : parent.fields[tConst.AF_START_ITERATION],
            endIteration  : parent.fields[tConst.AF_END_ITERATION]
        }
        AzureService.createTicket(auth, parent.fields[tConst.AF_PROJECT],"Initiative",  JSON.stringify(CreateData(newItem))).then((response) => {
            onSetRefresh();
        })
    })
}

function CreateData(newItem) {
    const _start = new Date(newItem.startIteration);
    const _end = new Date(newItem.endIteration);

    const data = [
        {
            "op": "add",
            "path": "/fields/"+tConst.AF_TITLE,
            "from": null,
            "value": newItem.newTitle
        },
        {
            "op": "add",
            "path": "/fields/"+tConst.AF_AREA_PATH,
            "value": newItem.path
        },    
       {
            "op": "add",
            "path": "/fields/"+tConst.AF_TAGS,
            "value": newItem.tags
        },
        {
            "op": "add",
            "path": "/fields/"+tConst.AF_START_ITERATION,
            "value": _start
          },
          {
            "op": "add",
            "path": "/fields/"+tConst.AF_END_ITERATION,
            "value": _end
          },
          {
            "op": "add",
            "path": "/fields/"+tConst.AF_INITIATIV,
            "value": newItem.initiative
          }
    
    ]

    if (newItem.parentId !== null && newItem.parentId!== 0) {
        data.push({
            "op": "add",
            "path": "/relations/-",
            "value": {
            "rel": "System.LinkTypes.Hierarchy-Reverse",
            "url": `https://dev.azure.com/sensio/OKR/_apis/wit/workItems/${newItem.parentId}`
            }
        });
    
    }

    console.log("CreateData: "+ JSON.stringify(data, null, 4))

    return data;
}
